<template>
	<div>
		<div id="e"></div>
		<input readonly v-model="editorData" type="hidden"/>
	</div>	
</template>

<script>
import axios from 'axios'
import E from "wangeditor"

export default {
	data(){
		return {
			editor:null,
			editorData: ''
		}
	},

	methods:{
		init(html) {
			if(this.editor!=null) this.editor.destroy()
			const editor = new E('#e')
			editor.config.menus = ["head","bold","fontSize","fontName","italic","foreColor","backColor","list","justify","image","undo","redo"]
			editor.config.customUploadImg = function (resultFiles, insertImgFn) {
				resultFiles.forEach((v)=>{
					let param = new FormData()
					param.append("file", v)
					let config = { headers: { "Content-Type": "multipart/form-data" } }
					axios.post(process.env.VUE_APP_IMG_API, param, config).then(function(res) {
						insertImgFn(res.data.data)
					})
				})
			}
			editor.config.onchange = (newHtml) => {
				this.editorData = newHtml
				this.$emit("update",newHtml)
			}
			editor.create()
			editor.txt.html(html)
			this.editor = editor
		}
	}
}
</script>

<style>
.w-e-panel-tab-title { display:none!important;}
/* .w-e-toolbar .w-e-active i { color:red!important; } */
</style>
